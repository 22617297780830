import React, { useState, useEffect, useCallback, useRef, createRef } from 'react';
import NumberFormat from "react-number-format";
import { Button, Modal, Spinner } from 'react-bootstrap';
import { useHistory } from 'react-router';

import api from '../../services/Api';
import {api as apiUrl} from '../../services/ApiURL';
import { toAbsoluteUrl } from "../../../_metronic/_helpers";

import { formatIntegerToFloat, formatIntegerToMoney, formatMoneyToInteger } from '../../utils/formatCurrency';
import { NumericFormat } from '../../components/NumericFormat';
import { IconButton, MenuItem, TextField } from '@material-ui/core';
import { useSelector } from 'react-redux';
import ListSimple, { BodyDataBaseProps, HeadDataBaseProps } from '../../components/ListSimple';

import '../../style.css';
import { CashRegister, yesOrNo } from '../../types/CashRegister';
import { extractDateStringFromTimestamp, getDate } from '../../utils/dateTimeHelper';
import { usePDF } from '@react-pdf/renderer';
import ReceiptPdv from './Pdf/ReceiptPdv';
import { convertPaymentMethodKeyToName } from '../../utils/convertPaymentMethodKeyToName';
import { useEventListener } from '../../hooks/event_handler';
import nfApiHelper from '../../utils/nfApiHelper';
import { Product } from '../../services/ProductService';
import LogService from '../../services/LogService';
import { useCompanyBranch } from '../../hooks/companyBranch';
import { useNf } from '../../hooks/nf';
import ApiResourceSelect from '../../components/ApiResourceSelect';
import CustomerService from '../../services/CustomerService';
import ProductService from '../../services/ProductService';
import { Customer } from '../../types/Customer';
import StockService from '../../services/StockService';
import useScanDetection from 'use-scan-detection';

type AddedProduct = {
    id: number,
    code: string,
    name: string,
    unitValue: number,
    quantity: number,
    totalValue: number,
    variationId?: number,
    type: "product" | "service" | "variation" | "kit",
}

type PaymentMethod = {
    id: number,
    key: string,
    name: string,
    paidValue: number
    installments: number,
    paymentDate: string,
}

type GenerateNfceParams = {
    sale: any,
    payments: PaymentMethod[],
    pdvTotalProducts: number,
    addedProducts: AddedProduct[],
}

const keys = {
    KEY_ENTER: 'Enter',
    KEY_O: 'KeyO',
    KEY_F2: 'F2',
    KEY_F4: 'F4',
    KEY_F6: 'F6',
    KEY_F7: 'F7',
    KEY_F9: 'F9',
};

export function NewPdv() {
    const [isSubmit, setIsSubmit] = useState(false);

    const { user } = useSelector((state: any) => state.auth);
    const { location: { pathname }, push: pushHistory } = useHistory();

    const installmentsArray = [];
    for(let i = 1; i <= 12; i++) {
      installmentsArray.push(i);
    }

    const addedProductsHeadData: HeadDataBaseProps[] = [
        { reference: 'id', value: 'COD' },
        { reference: 'name', value: 'PRODUTO' },
        { reference: 'quantity', value: 'QUANTIDADE' },
        { reference: 'unitValue', value: 'UNITÁRIO' },
        { reference: 'totalValue', value: 'TOTAL' },
        { reference: 'actions', value: 'AÇÃO' },
    ];
    const addedPaymentMethodsHeadData: HeadDataBaseProps[] = [
        { reference: 'date', value: 'DATA' },
        { reference: 'method', value: 'PAGAMENTO' },
        { reference: 'receivedValue', value: 'VALOR' },
        { reference: 'actions', value: 'AÇÃO' },
    ];
    const [addedProductsBodyData, setAddedProductsBodyData] = useState<BodyDataBaseProps[][]>([]);
    const [addedPaymentMethodsBodyData, setAddedPaymentMethodsBodyData] = useState<BodyDataBaseProps[][]>([]);

    const [cashRegister, setCashRegister] = useState<CashRegister>();
    const { selectedCompany } = useCompanyBranch({ withNfApiConfig: true });
    const { nfceEnabled } = useNf();

    const [finishedSalesCount, setFinishedSalesCount] = useState(0);
    const [currentScreen, setCurrentScreen] = useState('products');
    const [selectedProduct, setSelectedProduct] = useState<Product>({} as Product);
    const [quantity, setQuantity] = useState(0);
    const [unitValue, setUnitValue] = useState(0);
    const [totalValue, setTotalValue] = useState(0);
    const [addedProducts, setAddedProducts] = useState<AddedProduct[]>([]);
    const [notes, setNotes] = useState('');
    const [subtotal, setSubtotal] = useState(0);
    const [total, setTotal] = useState(0);
    const [installments, setInstallments] = useState(1);
    const [receivedValue, setReceivedValue] = useState(0);
    const [receivedTotal, setReceivedTotal] = useState(0);
    const [valueToPay, setValueToPay] = useState(0);
    const [changeValue, setChangeValue] = useState(0);
    const [discount, setDiscount] = useState(0);
    const [addition, setAddition] = useState(0);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<PaymentMethod>({
        id: 0,
        key: 'money',
        name: 'Dinheiro',
        paidValue: 0,
        installments: 0,
        paymentDate: '',
    });
    const [addedPaymentMethods, setAddedPaymentMethods] = useState<PaymentMethod[]>([]);
    const [selectedCustomer, setSelectedCustomer] = useState<Customer>({} as Customer);
    const [referredCustomer, setReferredCustomer] = useState<Customer>({} as Customer);
    const [manualCustomer, setManualCustomer] = useState<{ name: string; cpf: string; phone: string}>({
        name: '',
        cpf: '',
        phone: '',
    });

    const [msgError, setMsgError] = useState<string | JSX.Element>('');
    const [showModalError, setShowModalError] = useState(false);
    const [showModalConfirm, setShowModalConfirm] = useState(false);
    const [modalConfirmMsg, setModalConfirmMsg] = useState('');
    const [modalConfirmButtonLabel, setModalConfirmButtonLabel] = useState('');
    const [modalConfirmYesCallback, setModalConfirmYesCallback] = useState<Function>(() => null);
    const [showModalReferCustomer, setShowModalReferCustomer] = useState(false);
    const [modalReferCustomerErrorMsg, setModalReferCustomerErrorMsg] = useState('');

    const [pdvSaleId, setPdvSaleId] = useState('');
    const [receiptReady, setReceiptReady] = useState(false);
    const [instance, updatePDF] = usePDF({ document: <ReceiptPdv setReceiptReady={setReceiptReady} pdvSaleId={pdvSaleId} />});
    const [nfceDanfe, setNfceDanfe] = useState('');
    const receiptLink = useRef<HTMLAnchorElement>(null);
    const nfceLink = useRef<HTMLAnchorElement>(null);
    const productInput = useRef<HTMLInputElement>(null);
    const notesInput = useRef<HTMLInputElement>(null);
    const notesPaymentInput = useRef<HTMLInputElement>(null);
    const paymentMethodInput = useRef<HTMLInputElement>(null);
    const installmentsInput = useRef<HTMLInputElement>(null);
    const customersInput = useRef<HTMLInputElement>(null);
    const totalValueInput = useRef<HTMLInputElement>(null);
    const btnEnter = useRef<any>(null);

    useScanDetection({
        onComplete: (code: String) => {getProductByGtinEan(code)},
        // minLength: 13,
        // onError: () => {
        //     setMsgError("Erro ao ler código de barras!");
        //     setShowModalError(true);
        // }
    });

    async function getProductByGtinEan(code: String) {
        if (!code) {
            setMsgError("Erro ao ler código de barras!");
            setShowModalError(true);
            return;
        }
        
        const product = await api.get(`/products/codeGtinEan/${code}`);
        const variation = await api.get(`/variations/codeGtinEan/${code}`);

        if (product.data) {
            handleProductChange(product.data);
        } else if (variation.data) {
            handleProductChange(variation.data);
        } else {
            setMsgError("Produto não encontrado no sistema cadastrado com este GTIN/EAN!");
            setShowModalError(true);
        }
    }

    async function getCashRegister() {
        
        const cashRegister = await api.get("/pdvCashRegister/active/first");
        if(cashRegister.data.active === yesOrNo.NO) {
            pushHistory('/pdv');
        }
        setCashRegister(cashRegister.data ? cashRegister.data : undefined);
    }

    function handleProductChange(product: Product | null) {
        if(product === null) {
            return;
        }
        const productSelected = product;
        setSelectedProduct(productSelected);
        setQuantity(1);
        setUnitValue(formatMoneyToInteger(productSelected.saleValue));
        setTotalValue(formatMoneyToInteger(productSelected.saleValue));
    }

    function handleMoneyInputChange(event: any, setter: Function) {
        setter(formatMoneyToInteger(event.target.value))
    }

    function handleAddProductClick() {
        if(Object.keys(selectedProduct).length === 0) {
            setMsgError('Selecione um produto');
            setShowModalError(true);
            return;
        }

        if(totalValue <= 0) {
            setMsgError('O valor total deve ser maior que zero');
            setShowModalError(true);
            return;  
        }

        const addedProductsClone = [...addedProducts];

        addedProductsClone.push({
            id: selectedProduct.originalProductId ?? selectedProduct.id,
            code: selectedProduct.type === 'variation' ? `${selectedProduct.originalProductId ?? 0}-${selectedProduct.id}` : String(selectedProduct.id),
            name: selectedProduct.name,
            unitValue: unitValue,
            quantity: quantity,
            totalValue: totalValue,
            variationId: selectedProduct.type === 'variation' ? selectedProduct.id : undefined,
            type: selectedProduct.type,
        });

        const subtotalValue = addedProductsClone.reduce((accumulator, product) => (accumulator + product.totalValue), 0);

        setAddedProducts(addedProductsClone);
        setSelectedProduct({} as Product);
        setQuantity(0);
        setUnitValue(0);
        setTotalValue(0);
        setSubtotal(subtotalValue);
        if (btnEnter.current) {
            btnEnter.current.blur();
        }
    }

    function handlePaymentClick() {
        if(addedProducts.length === 0) {
            setMsgError('Adicione pelo menos um produto antes de prosseguir ao pagamento!');
            setShowModalError(true);
            return;
        }
        setCurrentScreen('payment');
    }

    function handlePaymentMethodChange(event: any) {
        const parsedObj = JSON.parse(event.target.value);
        const selected: PaymentMethod = {
            id: 0,
            key: parsedObj.key,
            name: parsedObj.name,
            paidValue: 0,
            installments: 0,
            paymentDate: '',
        };

        setSelectedPaymentMethod(selected);
        setReceivedValue(selected.key === 'money' ? 0 : valueToPay);
    }

    function handleAddPaymentMethodClick() {
        const selectedPaymentMethodClone = selectedPaymentMethod;
        const date = getDate().dateStr;
        selectedPaymentMethodClone.id = getNextPaymentMethodId();
        selectedPaymentMethodClone.paidValue = receivedValue;
        selectedPaymentMethodClone.installments = installments;
        selectedPaymentMethodClone.paymentDate = date;
        

        if(!selectedPaymentMethodClone.key) {
            setMsgError('Selecione uma forma de pagamento!');
            setShowModalError(true);
            return;
        }
        if(receivedValue <= 0) {
            setMsgError('Insira o valor recebido');
            setShowModalError(true);
            return;
        }
        if(receivedValue > valueToPay && selectedPaymentMethodClone.key !== 'money') {
            setMsgError('O valor recebido não pode ser maior que o valor total do pedido');
            setShowModalError(true);
            return;
        }

        const addedPaymentMethodsClone = [...addedPaymentMethods];
        const receivedTotalClone = receivedTotal + receivedValue;
        const valueToPayClone = subtotal - receivedTotalClone;

        addedPaymentMethodsClone.push(selectedPaymentMethodClone);

        setAddedPaymentMethods(addedPaymentMethodsClone);
        setReceivedTotal(receivedTotalClone);
        setValueToPay(valueToPayClone);

        setReceivedValue(0);
        setInstallments(1);
        setSelectedPaymentMethod({
            id: 0,
            key: 'money',
            name: 'Dinheiro',
            paidValue: 0,
            installments: 0,
            paymentDate: '',
        });
    }

    function handleRemoveProductMethodClick(id: number) {
        const addedProductMethodsClone = [...addedProducts];
        const productPdv = addedProductMethodsClone.find((product) => product.id === id);
        if(!productPdv) {
            return;
        }

        const addedProductMethodsFiltered = addedProductMethodsClone.filter((payment) => payment.id !== id);

        setAddedProducts(addedProductMethodsFiltered);
        if (addedProductMethodsFiltered.length == 0) {
            setCurrentScreen('products');
            setAddedPaymentMethods([]);
            setReceivedTotal(0);
            setReceivedValue(0);
            setInstallments(1);
            setSelectedPaymentMethod({
                id: 0,
                key: 'money',
                name: 'Dinheiro',
                paidValue: 0,
                installments: 0,
                paymentDate: '',
            });
        }
    }

    function handleRemovePaymentMethodClick(id: number) {
        const addedPaymentMethodsClone = [...addedPaymentMethods];
        const paymentMethod = addedPaymentMethodsClone.find((payment) => payment.id === id);
        if(!paymentMethod) {
            return;
        }

        const receivedTotalClone = receivedTotal - paymentMethod.paidValue;
        const valueToPayClone = subtotal - receivedTotalClone;

        const addedPaymentMethodsFiltered = addedPaymentMethodsClone.filter((payment) => payment.id !== id);

        setAddedPaymentMethods(addedPaymentMethodsFiltered);
        setReceivedTotal(receivedTotalClone);
        setValueToPay(valueToPayClone);
    }

    function handleCustomerChange(customer: Customer | null) {
        setModalReferCustomerErrorMsg('');
        setSelectedCustomer(customer ?? {} as Customer);
    }
    
    // Função para limpar o cliente manual
    const clearManualCustomer = () => {
        setManualCustomer({ name: '', cpf: '' , phone: ''});
    };
    
    // Função para limpar o cliente referenciado
    const clearReferredCustomer = () => {
        setReferredCustomer({} as Customer);
    };
    
    // Manipular alteração de campos manuais
    const handleManualCustomerChange = async(field: 'name' | 'cpf' | 'phone', value: string) => {
        setManualCustomer((prev) => ({
            ...prev,
            [field]: value,
        }));
        clearReferredCustomer();

        const searchCustomer = async() => {
            let val = value.replace(/\D/g, "");
            if (field == 'cpf' && val) {
                let { data } = await api.get(`/customer/cpf/${val}`);
                if (data) {
                    setSelectedCustomer(data);
                    setReferredCustomer(data);
                    setManualCustomer(data);
                }
            }
        }
        await searchCustomer();
    };
    
    // Seleção de cliente referenciado
    const handleReferCustomerClick = () => {
        if (!selectedCustomer.id) {
            setModalReferCustomerErrorMsg('Selecione um cliente!');
            return;
        }
        setReferredCustomer(selectedCustomer);
        clearManualCustomer();
        setShowModalReferCustomer(false);
    };

    function handleRemoveCustomerClick() {
        setReferredCustomer({} as Customer);
    }

    function handleCancelClick() {
        setShowModalConfirm(true);
        setModalConfirmMsg('Deseja mesmo cancelar a venda?')
        setModalConfirmButtonLabel('Sim');
        const callback = function() {
            resetPdv();
            setShowModalConfirm(false);
        };
        setModalConfirmYesCallback(() => callback);
    }

    const handleClickExit = useCallback(() => {
        pushHistory('/pdv');
    }, []);

    async function handleFinishClick(downloadReceipt: boolean = false) {
        setIsSubmit(true);

        if(addedProducts.length === 0) {
            setMsgError('Não é possível finalizar a venda sem produtos adicionados.');
            setShowModalError(true);
            setIsSubmit(false);
            return;
        }
        if(total <= 0) {
            setMsgError('O valor total do pedido deve ser maior que zero.');
            setShowModalError(true);
            setIsSubmit(false);
            return;
        }
        if(receivedTotal < total) {
            setMsgError('Não é possível finalizar a venda com valor recebido menor que o valor total do pedido.');
            setShowModalError(true);
            setIsSubmit(false);
            return;
        }

        if(!selectedCompany?.pdvStockLocationId) {
            setMsgError('Local de estoque padrão para vendas no PDV não foi configurado. Defina em Dados da Empresa -> Parâmetros -> PDV');
            setShowModalError(true);
            setIsSubmit(false);
            return;
        }

        const addedProductsClone = addedProducts;
        const addedPaymentMethodsClone = addedPaymentMethods;

        let customerId = referredCustomer.id;

        if (manualCustomer.cpf) {
            let cpf = manualCustomer.cpf.replace(/\D/g, "");
            let check_customer = await api.get(`/customer/cpf/${cpf}`);
            let customer = check_customer.data;

            if (customer) {
                setSelectedCustomer(customer);
                setReferredCustomer(customer);
                setManualCustomer(customer);
                customerId = customer.id;
            } else {
                if (!customerId && manualCustomer.name && manualCustomer.cpf) {
                    if (cpf.length < 11 || cpf.length > 11) {
                        setMsgError('Este CPF é inválido');
                        setShowModalError(true);
                        setIsSubmit(false);
                        return;
                    }
                    let phone = manualCustomer.phone ? manualCustomer.phone.replace(/\D/g, "") : null;
                    try {
                        const response = await api.post('/customer/pdvSaleCustomer', {
                            name: manualCustomer.name,
                            cpf,
                            cell: phone,
                            isPdvCustomer: "n",
                            registrationStatus: "active",
                            typePeople: "physical",
                            typeRegister: "customer",
                        });
                        customerId = response.data.id;
                    } catch (error: any) {
                        console.error('Erro ao cadastrar cliente manual:', error);
                        const errorMessage = error.response?.data || 'Erro ao cadastrar cliente manual';
                        setMsgError(errorMessage);
                        setShowModalError(true);
                        setIsSubmit(false);
                        return;
                    }
                }
            }
        }
    
        const rawPdvSale = {
            userId: user.id,
            customerId: customerId ?? null,
            cashRegisterId: cashRegister?.id ?? 0,
            totalValue: total,
            paidValue: receivedTotal,
            changeValue: changeValue,
            discount: discount,
            addition: addition,
            notes: notes,
            createdDate: new Date(),
        };

        let pdvSaleId = '';

        try {
            if(cashRegister === undefined) {
                throw 'Caixa não está aberto!';
            }

            const result = await api.post('pdvSales', rawPdvSale);
            pdvSaleId = result.data.id;
            let moneyTotal = 0;

            let rawPdvSaleItems = {};
            let rawPdvSalePayments = {};
            let pdvTotalProducts = 0;

            addedProductsClone.forEach(async (product) => {
               pdvTotalProducts += product.totalValue;
                rawPdvSaleItems = {
                    saleId: pdvSaleId,
                    productId: product.id,
                    variationId: product.variationId,
                    quantity: product.quantity,
                    unitValue: product.unitValue,
                    totalValue: product.totalValue,
                    createdDate: getDate().fullDateStr,
                };

                await api.post('pdvSaleItems', rawPdvSaleItems);
            });

            for (let payment of addedPaymentMethodsClone) {
                rawPdvSalePayments = {
                    saleId: pdvSaleId,
                    method: payment.key,
                    installments: payment.installments,
                    paidValue: payment.paidValue,
                    createdDate: payment.paymentDate,
                };

                await api.post('pdvSalePayments', rawPdvSalePayments);

                if(payment.key === 'money') {
                    moneyTotal += payment.paidValue;
                }
            }

            //Atualizar Caixa
            moneyTotal = moneyTotal - changeValue;

            const rawCashRegister = {
                id: cashRegister.id,
                currentValue: cashRegister.currentValue + moneyTotal,
            }

            await api.post(`pdvCashRegister/${cashRegister.id}`, rawCashRegister);

            // Atualizando valor em caixa na conta bancaria selecionada
            if (cashRegister && moneyTotal) {
                await api.patch(`/accountBank/cashierMoney/${cashRegister?.bankAccount}`, {valueToAdd: moneyTotal});
            }
            
            await createBillToReceive(result.data, addedPaymentMethodsClone);

            await moveToStock(result.data, addedProducts);

            setFinishedSalesCount(finishedSalesCount + 1);

            if(downloadReceipt) {
                const canIssueNfce = nfceEnabled;
                if(!canIssueNfce) {
                    setPdvSaleId(pdvSaleId);
                } else {
                    //Emitir NFC-E
                    const generatedNfce = await generateNfce({
                        sale: result.data,
                        payments: addedPaymentMethodsClone,
                        pdvTotalProducts,
                        addedProducts: addedProductsClone,
                    });
                    if(!generatedNfce) {
                        setMsgError('A venda foi processada, porém ocorreu erro ao emitir a nota do consumidor');
                        setShowModalError(true);
                        setIsSubmit(false);
                        resetPdv();
                        return;
                    }

                    const rawUpdate = { nfceId: generatedNfce.id };
                    await api.put(`pdvSales/${pdvSaleId}`, rawUpdate);
                }
            }

            LogService.logRegister({
                itemId: result.data.id,
                module: 'PDV',
                itemName: 'Venda'
            });

        } catch (error) {
            console.log(error);
            setMsgError('Erro ao processar venda');
            setShowModalError(true);
            setIsSubmit(false);
            return;
        }

        setMsgError((<div className='text-center text-success'>Pedido finalizado!</div>));
        setShowModalError(true);
        setIsSubmit(false);
        resetPdv();
    }

    async function createBillToReceive(sale: any, payments: PaymentMethod[]) {
        const staticData = {
            customer: selectedCustomer,
            occurrence: payments.length > 1 ? 'Parcelada' : null,
        };

        for(let i = 0; i < payments.length; i++) {
            const payment = payments[i];
            const payDate = payment.paymentDate;
            const payDateReverse = payDate.split('/').reverse().join('-');
            const name = payments.length > 1 ? `Venda N° ${sale.id}/${i + 1}` : `Venda N° ${sale.id}`
            
            let paidValue = payment.paidValue;
            if((i + 1) === payments.length
            && payment.key === 'money'
            && sale.paidValue > sale.totalValue) {
                paidValue = paidValue - sale.changeValue;
            }
            
            const responseBanks = await api.get('/accountsBank');
            var bankAccountId;

            for (let indexBank = 0; indexBank < responseBanks.data.length; indexBank++) {
                if (responseBanks.data[indexBank].standardAccountForRecipes == "y") {
                    bankAccountId = responseBanks.data[indexBank].id;
                }
            }

            const bank = bankAccountId ? await api.get(`/accountBank/${convertPaymentMethodKeyToName(payment.key) == "Dinheiro" ? cashRegister?.bankAccount : bankAccountId}`) : null;
            const dreSubCategory = await api.get(`dre/sub/${selectedCompany.pdvDreSubCategoryId}`);

            const billData = {
                isPaid: true,
                name: name,
                amount: formatIntegerToFloat(paidValue),
                totalPaid: formatIntegerToFloat(paidValue),
                payedDate: payDateReverse,
                remaining: 0,
                status: 'paid',
                bankAccount: convertPaymentMethodKeyToName(payment.key) == "Dinheiro" ? cashRegister?.bankAccount : bankAccountId,
                nameBank: bank ? bank.data.nameBank : null,
                categoryName: dreSubCategory.data?.name ?? 'Vendas',
                dreSubCategoryId: dreSubCategory.data?.id ?? 0,
                // centerCost: '',
                comments: sale.notes,
                customer: sale.customerId,
                docNumber: String(sale.id),
                dueDate: payDateReverse,
                issuanceDate: payDateReverse,
                occurrence: staticData.occurrence,
                payment: convertPaymentMethodKeyToName(payment.key),
                recordType: 'account',
            };
    
            await api.post("billsToReceive", billData);
        }
    }

    const moveToStock = useCallback(async (sale: any, addedProducts: AddedProduct[]) => {
        const mappedProducts = addedProducts.map((product) => {
            return {
                INSTANCE: {
                    id: product.variationId || product.id,
                    hasVariation: product.variationId ? 'y' : 'n',
                    type: product.type,
                },
                unitaryValue: formatIntegerToFloat(product.unitValue),
                amount: product.quantity,
            };
        });

        await StockService.launchInStock({
            products: mappedProducts,
            typeOrder: 'pdv',
            isOs: false,
            orderSelected: String(sale.id),
            stockLocationMove: String(selectedCompany?.pdvStockLocationId || '0'),
            observationMove: `Venda PDV N° ${sale.id}`,
        });
    }, [selectedCompany]); 

    const generateNfce = useCallback(async ({sale, payments, pdvTotalProducts, addedProducts}: GenerateNfceParams) => {
        const response = await api.get('nfceConfig');
        const config = response.data[0];

        if(!config || !selectedCompany) return null;

        const seriesNfce = selectedCompany.nfEnv === 'production' ? (selectedCompany.nfApiConfig.serie_nfce_producao ?? '1') : (selectedCompany.nfApiConfig.serie_nfce_homologacao ?? '1');
        const invoiceNumber = selectedCompany.nfEnv === 'production' ? (selectedCompany.nfApiConfig.proximo_numero_nfce_producao ?? '1') : (selectedCompany.nfApiConfig.proximo_numero_nfce_homologacao ?? '1');


        const nfceProducts = await Promise.all(addedProducts.map(async (addedProd) => {
            const currentProduct = (await api.get(`products/${addedProd.id}`)).data;

            return {
                icms: currentProduct.icmsRate || '0',
                cfop: config.cfopConsumer || "5101",
                ipi: currentProduct.ipiRate || '0',
                ncm: currentProduct.ncm ?? '',
                total: formatIntegerToFloat(addedProd.totalValue),
                amount: addedProd.quantity,
                unitaryValue: formatIntegerToFloat(addedProd.unitValue),
                INSTANCE: currentProduct as Product,
            };
        }));

        const nfceInstallments = payments.map((paymentObj, index) => {
            let paidValue = paymentObj.paidValue;

            return {
                date: paymentObj.paymentDate,
                value: formatIntegerToFloat(paidValue),
                comments: "",
                payment: paymentObj.name,
            };
        });
    
        try {
            const raw = {
                typeAttendance: config?.typeAttendance,
                seriesNfce: seriesNfce,
                invoiceNumber: invoiceNumber,
                customer: sale.customerId ? Number(sale.customerId) : 0,
                seller: 0,
                customerDontIdentified: sale.customerId ? 'n' : 'y',
                indicator: '',
                intermediaryCnpj: '',
                identifierRegisterIntermediary: '',
                observation: '',
                internalObservation: `Venda PDV N° ${sale.id}`,
                priceList: 0,
                bcIcms: 0,
                valueIcms: 0,
                valueProducts: formatIntegerToFloat(pdvTotalProducts),
                valueFreight: 0,
                valueExpenses: 0,
                discountMoney: formatIntegerToFloat(sale.discount),
                discountPercentage: 0,
                totalValue: formatIntegerToFloat(sale.totalValue),
                amountInstallments: payments.length,
                grossWeight: 0,
                liquidWeight: 0,
                volumes: '',
                species: '',
                brand: '',
                numbering: '',
                modalityFreight: 9,
                destinationOperation: 1,
                carrier: 0,
                plateVehicle: '',
                date: extractDateStringFromTimestamp(),
                products: JSON.stringify(nfceProducts),
                installments: JSON.stringify(nfceInstallments),
                situation: "open",
            };

            const createdNfce = await api.post("/nfce", raw);
            const issueResponse = await api.post(`nfce/issue/${createdNfce.data.id}`);
            const apiLastResponse = JSON.parse(issueResponse.data.api_last_response);
            
            if(!nfApiHelper.isAuthorized(issueResponse.data.api_situation)) {
                return null;
            }

            setNfceDanfe(`${apiLastResponse['url']}${apiLastResponse['body']['caminho_danfe']}`);

            return createdNfce.data;
        } catch (error) {
            console.log(error);
            return null;
        }
    }, [selectedCompany]);

    useEffect(() => {
        getCashRegister();
    }, [finishedSalesCount]);

    useEffect(() => {
        setTotalValue(quantity * unitValue);
    }, [quantity, unitValue]);

    useEffect(() => {
        const totalClone = subtotal - discount + addition;
        let valueToPayClone = totalClone - receivedTotal;
        if(valueToPayClone < 0) {
            setChangeValue(receivedTotal - totalClone);
            valueToPayClone = 0;
        } else {
            setChangeValue(0);
        }

        setTotal(totalClone);
        setValueToPay(valueToPayClone);
    }, [subtotal, discount, addition, receivedTotal]);
    
    useEffect(() => {
        const list: BodyDataBaseProps[][] = [];
        const aux = addedProducts;

        aux.forEach((product) => {
            const data: BodyDataBaseProps[] = [
                { for: "id", value: product.code, id: true },
                { for: "name", value: product.name },
                { for: "quantity", value: String(product.quantity) },
                { for: "unitValue", value: formatIntegerToMoney(product.unitValue, true) },
                { for: "totalValue", value: formatIntegerToMoney(product.totalValue, true) },
                { for: "actions", value: (
                        <span
                            style={{cursor: 'pointer'}}
                            title="Remover"
                            onClick={() => handleRemoveProductMethodClick(product.id)}
                        >
                            <i className="flaticon-delete text-danger p-0"></i>
                        </span>
                    )
                },
            ];

            list.push(data);
        });

        setAddedProductsBodyData(list);
    }, [addedProducts]);

    useEffect(() => {
        const list: BodyDataBaseProps[][] = [];
        const aux = addedPaymentMethods;

        aux.forEach((payment) => {
            const data: BodyDataBaseProps[] = [
                { for: "date", value: payment.paymentDate },
                { for: "method", value: payment.name },
                { for: "receivedValue", value: formatIntegerToMoney(payment.paidValue, true) },
                { for: "actions", value: (
                        <span
                            style={{cursor: 'pointer'}}
                            title="Remover"
                            onClick={() => handleRemovePaymentMethodClick(payment.id)}
                        >
                            <i className="flaticon-delete text-danger p-0"></i>
                        </span>
                        // <a
                        //     href="javascript:void(0)"
                        //     title="Remover"
                        //     onClick={() => handleRemovePaymentMethodClick(payment.id)}
                        // >
                        //     <i className="flaticon-delete text-danger p-0"></i>
                        // </a>
                    )
                },
            ];

            list.push(data);
        });

        setAddedPaymentMethodsBodyData(list);
    }, [addedPaymentMethods]);

    useEffect(() => {
        updatePDF();
    }, [pdvSaleId]);

    useEffect(() => {
        if(receiptReady && !instance.loading && !instance.error && pdvSaleId !== '') {
            if(receiptLink.current != undefined) {
                receiptLink.current.click();
                setPdvSaleId('');
            }
        }
    }, [receiptReady, instance.loading, instance.error, pdvSaleId]);

    useEffect(() => {
        if(!nfceDanfe || !nfceLink.current) {
            return;
        }

        nfceLink.current.click();
        setNfceDanfe('');
    }, [nfceDanfe]);

    useEventListener('keydown', keyDownHandler);

    function keyDownHandler(event: KeyboardEvent) {
        if (event.code === keys.KEY_F9) {
            event.preventDefault();
            handleCancelClick();
        } else if (event.code === keys.KEY_O && event.ctrlKey) {
            event.preventDefault();
            if(currentScreen === 'products' && notesInput.current !== null) {
                notesInput.current.querySelector('textarea')?.focus();
            }
            if(currentScreen === 'payment' && notesPaymentInput.current !== null) {
                notesPaymentInput.current.querySelector('textarea')?.focus();
            }
        }

        if(currentScreen === 'products') {
            if (event.code === keys.KEY_F2) {
                event.preventDefault();
                handlePaymentClick();
            } else if (event.code === keys.KEY_ENTER
            && document.activeElement !== productInput.current?.querySelector('input')
            && document.activeElement !== notesInput.current?.querySelector('textarea')
            && totalValueInput.current && totalValueInput.current.children[1].children[0].getAttribute('value') != "0,00"
            && productInput.current && productInput.current.children[1].children[0].getAttribute('value') != ""
            ) {
                event.preventDefault();
                handleAddProductClick();
            }
        } else {
            if (event.code === keys.KEY_F4) {
                event.preventDefault();
                if(referredCustomer.id)
                    handleRemoveCustomerClick();
                else
                    setShowModalReferCustomer(true);
            } else if (event.code === keys.KEY_F6) {
                event.preventDefault();
                handleFinishClick();
            } else if (event.code === keys.KEY_F7) {
                event.preventDefault();
                handleFinishClick(true);
            } else if (event.code === keys.KEY_ENTER
            && document.querySelector('.MuiPaper-root.MuiPopover-paper') === null
            && document.activeElement !== paymentMethodInput.current
            && document.activeElement !== installmentsInput.current?.querySelector('input')
            && document.activeElement !== customersInput.current?.querySelector('input')
            && document.activeElement !== notesPaymentInput.current?.querySelector('textarea')) {
                event.preventDefault();
                handleAddPaymentMethodClick();
            }
        }
    }

    function getNextPaymentMethodId() {
        const addedPaymentMethodsClone = addedPaymentMethods;
        let maxId = 0;
        if(addedPaymentMethodsClone.length > 0) {
            maxId = Math.max(...addedPaymentMethodsClone.map(payment => payment.id));
        }
        
        return maxId + 1;
    }

    function resetPdv() {
        setCurrentScreen('products');
        setSelectedProduct({} as Product);
        setQuantity(0);
        setUnitValue(0);
        setTotalValue(0);
        setNotes('');
        setAddedProducts([]);
        setSubtotal(0);
        setSelectedPaymentMethod({
            id: 0,
            key: 'money',
            name: 'Dinheiro',
            paidValue: 0,
            installments: 0,
            paymentDate: '',
        });
        setInstallments(1);
        setReceivedValue(0);
        setAddedPaymentMethods([]);
        setDiscount(0);
        setAddition(0);
        setReceivedTotal(0);
        setValueToPay(0);
        setSelectedCustomer({} as Customer);
        setReferredCustomer({} as Customer);
        setManualCustomer({
            name: '',
            cpf: '',
            phone: '',
        });
    }

    return (
        <div className="pdv-container">
            {/* Modal Error */}
            <Modal
                show={showModalError}
                onHide={() => setShowModalError(false)}
                aria-labelledby="contained-modal-warning"
                centered
            >
                <Modal.Header closeButton>
                <Modal.Title className="d-flex align-items-center">
                    <i className="flaticon2-warning icon-xl text-warning mr-3"></i>
                    Atenção
                </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                <b>{msgError}</b>
                </Modal.Body>

                <Modal.Footer>
                <Button variant="secondary" onClick={() => setShowModalError(!showModalError)}>Fechar</Button>
                </Modal.Footer>
            </Modal>

            {/* Modal Confirm */}
            <Modal
                show={showModalConfirm}
                onHide={() => setShowModalConfirm(false)}
                aria-labelledby="contained-modal-confirm"
                centered
            >
                <Modal.Header closeButton>
                <Modal.Title className="d-flex align-items-center">
                    <i className="flaticon2-warning icon-xl text-warning mr-3"></i>
                    Atenção
                </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                <b>{modalConfirmMsg}</b>
                </Modal.Body>

                <Modal.Footer>
                <Button variant="secondary" onClick={() => setShowModalConfirm(!showModalConfirm)}>Cancelar</Button>
                <Button variant="primary" onClick={() => modalConfirmYesCallback()}>{modalConfirmButtonLabel}</Button>
                </Modal.Footer>
            </Modal>

            {/* Modal refer customer */}
            <Modal
                size='sm'
                show={showModalReferCustomer}
                onHide={() => setShowModalReferCustomer(false)}
                aria-labelledby="contained-modal-refer-customer"
                centered
            >
                <Modal.Header closeButton>
                <Modal.Title className="d-flex align-items-center">
                    Referenciar Cliente
                </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div className="card-body mt-0 pt-0">
                        <div className="row">
                            <div className="col-lg-12">
                                <ApiResourceSelect
                                    label="Cliente"
                                    getOptionLabel={(option: Customer) => `${option.id} - ${option.name}`}
                                    value={selectedCustomer}
                                    onSelect={handleCustomerChange}
                                    apiSearchHandler={(typedText) => CustomerService.getCustomersFiltered({ name: typedText, nameWithId: true })}
                                    inputRef={customersInput}
                                />
                                <div className="text-danger">{modalReferCustomerErrorMsg}</div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={() => {setShowModalReferCustomer(!showModalReferCustomer); setModalReferCustomerErrorMsg('')}}>Fechar</Button>
                    <Button variant="primary" onClick={handleReferCustomerClick}>Selecionar</Button>
                </Modal.Footer>
            </Modal>

            <div className="content container-fluid">
                <div className="crms-title row bg-light my-0 pt-4 px-5">
                    <div className="col ml-5 p-0">
                        <h3 className="page-title">
                        <span className="page-title-icon bg-gradient-primary text-white mr-2">
                        <i className="feather-grid"></i>
                        </span> PDV </h3>
                    </div>
                    <div className="col p-0 text-right mr-5">
                    {/* addProduct */}
                    <Button
                        type='button'
                        className='btn btn-primary font-weight-bold text-white todo-list-add-btn btn-rounded'
                        variant="primary"
                        onClick={handleClickExit}
                    >
                        Sair do PDV
                    </Button>
                    </div>
                </div>

                {/* <!-- TAB PRODUTOS --> */}
                <div className={`card my-0 border-0 ${currentScreen === 'products' ? '' : 'd-none'}`} id="tabProducts">
                    <div className="card-body bg-light">                        
                        <div className="row">
                            <div className="col-md-5">
                                <div className="row">
                                    <div className="form-group col-md-12">
                                        <ApiResourceSelect
                                            label="Produto"
                                            getOptionLabel={(option) => option.name}
                                            value={selectedProduct}
                                            onSelect={handleProductChange}
                                            apiSearchHandler={(typedText) => ProductService.getProductsAndVariationsGtinEanFiltered({ name: typedText })}
                                            inputRef={productInput}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-group col-md-4">
                                        {/* quantity */}
                                        <NumericFormat 
                                            className="inputSmaller"
                                            label="Quantidade"
                                            decimalScale={0}
                                            thousandSeparator={''}
                                            withPrefix={false}
                                            value={quantity}
                                            onChange={(e) => setQuantity(parseInt(e.target.value))}
                                        />
                                    </div>
                                    <div className="form-group col-md-4">
                                        {/* unit_value */}
                                        <NumericFormat 
                                            className="inputSmaller"
                                            label="Valor Unitário"
                                            value={formatIntegerToMoney(unitValue)}
                                            onChange={(e) => handleMoneyInputChange(e, setUnitValue)}
                                        />
                                    </div>
                                    <div className="form-group col-md-4">
                                        {/* total_value */}
                                        <NumericFormat 
                                            id="totalValue"
                                            className="inputSmaller"
                                            disabled
                                            label="Total"
                                            value={formatIntegerToMoney(totalValue)}
                                            ref={totalValueInput}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        {/* addProduct */}
                                        <Button
                                            type='button'
                                            className='btn btn-info form-control'
                                            variant="primary"
                                            onClick={handleAddProductClick}
                                            ref={btnEnter}
                                        >
                                        INCLUIR (ENTER)
                                        </Button>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="form-group col-md-12">
                                        {/* sale_notes */}
                                        <TextField
                                            label="Observações (CTRL+O)"
                                            multiline
                                            rows="4"
                                            margin="normal"
                                            variant="outlined"
                                            value={notes}
                                            onChange={(e) => setNotes(e.target.value)}
                                            ref={notesInput}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-7 p-4">
                                <div className="border border-secondary" style={{"minHeight":"300px"}}>
                                    <ListSimple
                                        headData={addedProductsHeadData}
                                        bodyData={addedProductsBodyData}
                                    />
                                    {addedProducts.length === 0 ? (
                                        <h3 className="text-center text-success pt-5 mt-5">CAIXA LIVRE</h3>
                                    ) : (<></>)}
                                </div>
                                <div className="col-12 bg-primary text-white mt-2 p-3">
                                    <div className="row" style={{"fontWeight":"bold"}}>
                                        <div className="col-md-6 text-left">SUBTOTAL</div>
                                        <div className="col-md-6 text-right">{formatIntegerToMoney(subtotal, true)}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <!-- TAB PAGAMENTOS --> */}
                <div className={`card my-0 border-0 ${currentScreen === 'payment' ? '' : 'd-none'}`} id="tabPayment">
                    <div className="card-body bg-light">
                        <div className="row">
                            <div className="col-md-5">
                                <div className="row">
                                    <div className="form-group col-md-5">
                                        {/* payment_method */}
                                        <TextField
                                            select
                                            label="Forma de Pagamento"
                                            margin="normal"
                                            variant="outlined"
                                            value={JSON.stringify({key: selectedPaymentMethod.key, name: selectedPaymentMethod.name})}
                                            onChange={(e) => handlePaymentMethodChange(e)}
                                            ref={paymentMethodInput}
                                        >
                                            <MenuItem
                                                key="0"
                                                value={JSON.stringify({key: 'money', name: 'Dinheiro'})}
                                            >
                                                Dinheiro
                                            </MenuItem>
                                            <MenuItem
                                                key="1"
                                                value={JSON.stringify({key: 'credit_card', name: 'Cartão de Crédito'})}
                                            >
                                                Cartão de Crédito
                                            </MenuItem>
                                            <MenuItem
                                                key="2"
                                                value={JSON.stringify({key: 'debit_card', name: 'Cartão de Débito'})}
                                            >
                                                Cartão de Débito
                                            </MenuItem>
                                            <MenuItem
                                                key="3"
                                                value={JSON.stringify({key: 'pix', name: 'Pix'})}
                                            >
                                                Pix
                                            </MenuItem>

                                        </TextField>
                                    </div>
                                    <div className="form-group col-md-3">
                                        {/* installments */}
                                        <TextField
                                            select
                                            label="Parcelas"
                                            margin="normal"
                                            variant="outlined"
                                            value={installments}
                                            onChange={(e) => setInstallments(parseInt(e.target.value))}
                                            ref={installmentsInput}
                                        >
                                            {installmentsArray.map((value, index) => (
                                            <MenuItem key={index} value={value}>
                                                {value}
                                            </MenuItem>
                                            ))}

                                        </TextField>
                                    </div>
                                    <div className="form-group col-md-4">
                                        {/* received_value */}
                                        <NumericFormat 
                                            className="inputSmaller"
                                            label="Valor Recebido"
                                            value={formatIntegerToMoney(receivedValue)}
                                            onChange={(e) => setReceivedValue(formatMoneyToInteger(e.target.value))}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <p className="text-primary">A pagar: <span className="ml-2" style={{"fontSize":"24px"}}>{formatIntegerToMoney(valueToPay, true)}</span></p>
                                    </div>
                                    <div className="col-md-6 text-right">
                                        {/* addPayment */}
                                        <Button
                                            type='button'
                                            className='btn btn-info'
                                            variant="primary"
                                            onClick={handleAddPaymentMethodClick}
                                        >
                                        ADICIONAR (ENTER)
                                        </Button>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <NumberFormat
                                        margin="normal"
                                        className="inputSmaller"
                                        customInput={TextField}
                                        variant="outlined"
                                        format="###.###.###-##"
                                        label="CPF*"
                                        mask="_"
                                        value={manualCustomer.cpf}
                                        onChange={(e: any) => handleManualCustomerChange('cpf', e.target.value)}
                                        disabled={!!referredCustomer.id}
                                    />
                                    <TextField
                                        label="Nome do Cliente*"
                                        variant="outlined"
                                        margin="normal"
                                        value={manualCustomer.name}
                                        onChange={(e) => handleManualCustomerChange('name', e.target.value)}
                                        disabled={!!referredCustomer.id}
                                    />
                                    <NumberFormat
                                        margin="normal"
                                        className="inputSmaller"
                                        customInput={TextField}
                                        variant="outlined"
                                        format="(##) #####-####"
                                        label="Telefone"
                                        mask="_"
                                        value={manualCustomer.phone}
                                        onChange={(e) => handleManualCustomerChange('phone', e.target.value)}
                                        disabled={!!referredCustomer.id}
                                    />
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div 
                                            className={`border border-secondary ${addedPaymentMethods.length > 0 ? '' : 'd-none'}`}
                                            id="payments_wrapper"
                                            style={{"height":"105px","overflow":"auto"}}
                                        >
                                            <ListSimple
                                                headData={addedPaymentMethodsHeadData}
                                                bodyData={addedPaymentMethodsBodyData}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="form-group col-md-12">
                                        {/* payment_notes */}
                                        <TextField
                                            label="Observações (CTRL+O)"
                                            multiline
                                            rows="3"
                                            margin="normal"
                                            variant="outlined"
                                            value={notes}
                                            onChange={(e) => setNotes(e.target.value)}
                                            ref={notesPaymentInput}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-7 p-4">
                                <div className="border border-secondary" style={{"minHeight":"200px"}}>
                                    <ListSimple
                                        headData={addedProductsHeadData}
                                        bodyData={addedProductsBodyData}
                                    />
                                </div>
                                <div className="col-12">
                                <div className="row mt-2">
                                <div className="col-md-6">
                                    {referredCustomer.id ? (
                                        <p className="text-info">
                                            Cliente Referenciado: {referredCustomer.name}
                                            <Button
                                                type="button"
                                                size="sm"
                                                className="btn btn-danger ml-2"
                                                onClick={clearReferredCustomer}
                                            >
                                                Remover
                                            </Button>
                                        </p>
                                    ) : (
                                        <Button
                                            type="button"
                                            size="sm"
                                            className="btn btn-primary"
                                            onClick={() => setShowModalReferCustomer(true)}
                                        >
                                            + Referenciar Cliente
                                        </Button>
                                    )}
                                </div>
                            </div>
                                    <div className="row my-1">
                                        <div className="col-md-4">
                                            {/* discount */}
                                            <NumericFormat 
                                                className="inputSmaller"
                                                label="Desconto"
                                                value={formatIntegerToMoney(discount)}
                                                disabled={addedPaymentMethods.length > 0}
                                                onChange={(e) => handleMoneyInputChange(e, setDiscount)}
                                            />
                                        </div>
                                        <div className="col-md-4"></div>
                                        <div className="col-md-4">
                                            {/* addition */}
                                            <NumericFormat 
                                                className="inputSmaller"
                                                label="Acréscimo"
                                                value={formatIntegerToMoney(addition)}
                                                disabled={addedPaymentMethods.length > 0}
                                                onChange={(e) => handleMoneyInputChange(e, setAddition)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="card my-0 fixed-bottom">
                    <div className="card-body bg-light">
                        <div className="row">
                            <div className="col-md-4 bg-light main-drop d-flex align-items-center">
                                {/* <span className="user-img"><img style={{width: '80px!important'}} src="#" alt="" /></span> */}
                                <div className="symbol symbol-md bg-light-primary mr-3 flex-shrink-0">
                                    <img src={toAbsoluteUrl(user.photo ? `${apiUrl}/${user.photo}` : "/media/users/300_21.jpg")} alt="" />
                                </div>
                                <span className="ml-2"> {user.firstname} {user.lastname} - OPERADOR</span>
                            </div>
                            <div className="col-md-4">
                                {currentScreen === 'payment' ? (
                                    <div className="row" id="paymentTotals">
                                        <div className="col">
                                            <p>Pagamentos</p>
                                            <p>{formatIntegerToMoney(receivedTotal, true)} </p>
                                        </div>
                                        <div className="col">
                                            <p>Troco</p>
                                            <p>{formatIntegerToMoney(changeValue, true)} </p>
                                        </div>
                                        <div className="col">
                                            <p>Total</p>
                                            <p>{formatIntegerToMoney(total, true)} </p>
                                        </div>
                                    </div>
                                ) : <></>}
                            </div>
                            <div className="col-md-4 text-center bg-light">
                                {/* cancelBtn */}
                                <Button
                                    type='button'
                                    className='btn btn-danger'
                                    variant="primary"
                                    onClick={handleCancelClick}
                                >
                                Cancelar (F9)
                                </Button>
                                
                                {currentScreen === 'products' ? (
                                    <>
                                        <span className="mx-5"></span>
                                        {/* cancelBtn */}
                                        <Button
                                            type='button'
                                            className='btn btn-success'
                                            variant="primary"
                                            onClick={handlePaymentClick}
                                        >
                                            Pagamento (F2)
                                        </Button>
                                    </>
                                ) : (
                                    <>
                                        <span className="mx-2"></span>
                                        {/* receiptBtn */}
                                        <Button
                                            type='button'
                                            className='btn btn-info'
                                            variant="primary"
                                            disabled={isSubmit}
                                            onClick={() => handleFinishClick(true)}
                                        >
                                            {
                                                isSubmit ?
                                                    <>
                                                        <Spinner
                                                            as="span"
                                                            animation="border"
                                                            size="sm"
                                                            role="status"
                                                            aria-hidden="true"
                                                        />

                                                        <span className='ml-2'>
                                                            Aguarde...
                                                        </span>

                                                    </>
                                                :
                                                    <span>Recibo (F7)</span>
                                            }
                                        </Button>
                                        <span className="mx-2"></span>
                                        {/* finishBtn */}
                                        <Button
                                            type='button'
                                            className='btn btn-success'
                                            variant="primary"
                                            disabled={isSubmit}
                                            onClick={() => handleFinishClick()}
                                        >
                                            {
                                                isSubmit ?
                                                    <>
                                                        <Spinner
                                                            as="span"
                                                            animation="border"
                                                            size="sm"
                                                            role="status"
                                                            aria-hidden="true"
                                                        />

                                                        <span className='ml-2'>
                                                            Aguarde...
                                                        </span>

                                                    </>
                                                :
                                                    <span>Finalizar (F6)</span>
                                            }
                                        </Button>
                                    </>
                                )}
                                
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            {!instance.loading && !instance.error ? (
                <a style={{ display: 'none' }} ref={receiptLink} href={instance.url ?? undefined} download="recibo.pdf">
                    Download
                </a>
            ) : ''}
            {nfceDanfe && (
                <a style={{ display: 'none' }} ref={nfceLink} href={nfceDanfe} target="_blank" download="nfce.pdf">
                    Download
                </a>
            )}
        </div>
      );
}