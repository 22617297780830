import React, { useEffect, useState } from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
  Font,
} from "@react-pdf/renderer";
import { useHistory, useParams } from "react-router";
import api from "../../../services/Api";
import { PdvSale } from "../../../types/PdvSale";
import { PdvSaleItem } from "../../../types/PdvSaleItem";
import { PdvSalePayment } from "../../../types/PdvSalePayment";
import { formatIntegerToMoney } from "../../../utils/formatCurrency";
import Table from "./Table";
import TableRow from "./TableRow";
import TableCell from "./TableCell";
import { getDate } from "../../../utils/dateTimeHelper";
import { convertPaymentMethodKeyToName } from "../../../utils/convertPaymentMethodKeyToName";

// Create styles
const styles = StyleSheet.create({
  section: {
    margin: 16,
    padding: 16,
    fontSize: 11,
  },
  viewer: {
    width: window.innerWidth, //the pdf viewer will take up all of the width and height
    height: window.innerHeight,
  },
  table: {
    paddingTop: 16,
  },
  totals: {
    paddingTop: 16,
  },
  footer: {
    paddingTop: 24,
    fontSize: 14,
  },
});

const itemsTable = {
  cell1: {
    width: '10%',
  },
  cell2: {
    width: '30%',
  },
  cell3: {
    width: '10%',
  },
  cell4: {
    width: '25%',
  },
  cell5: {
    width: '25%',
  },
};

const paymentsTable = {
  cell1: {
    width: '33.33%',
  },
  cell2: {
    width: '33.33%',
  },
  cell3: {
    width: '33.33%',
  },
}

type UrlParam = {
  pdvSaleId: string
}

type Product = {
  id: number,
  name: string,
  saleValue: string,
}

type ReceiptPdvParams = {
  pdvSaleId?: string,
  setReceiptReady: (value: React.SetStateAction<boolean>) => void,
}

// Create Document Component
export default function ReceiptPdv({ pdvSaleId, setReceiptReady }: ReceiptPdvParams) {
  const [pdvSale, setPdvSale] = useState<PdvSale>({} as PdvSale);
  const [pdvSaleItems, setPdvSaleItems] = useState<PdvSaleItem[]>([]);
  const [pdvSalePayments, setPdvSalePayments] = useState<PdvSalePayment[]>([]);

  async function getPdvSale(id: string) {
    if(id.length <= 0) {
      return;
    }

    const result = await api.get(`/pdvSales/${id}`);
    const pdvSale = result.data;

    if (!result.data) {
      return;
    }

    setPdvSale(pdvSale);

    const resultItems = await api.get(`/pdvSaleItems/pdvSale/${id}`, { params: { withProduct: true } });
    const pdvSaleItems = resultItems.data;
    setPdvSaleItems(pdvSaleItems);

    const resultPayments = await api.get(`/pdvSalePayments/pdvSale/${id}`);
    const pdvSalePayments = resultPayments.data;
    setPdvSalePayments(pdvSalePayments);

    setReceiptReady(true);
  }

  useEffect(() => {
    if(pdvSaleId === '') {
      setPdvSale({} as PdvSale);
      setPdvSaleItems([]);
      setPdvSalePayments([]);
      setReceiptReady(false);
      return;
    }

    getPdvSale(pdvSaleId ?? '');
  }, [pdvSaleId]);

  // return (
  //     <Document>
  //       <Page size="A4">
  //         <View><Text>aaa</Text></View>
  //       </Page>
  //     </Document>
  // );
  return (
    <Document>
      {/*render a single page*/}
      <Page size="A4">
        <View style={styles.section}>
          <Text style={{ textAlign: 'center' }}>RECIBO {pdvSale.id}</Text>
          <Table>
            <View>
              <TableRow>
                <TableCell width={itemsTable.cell1.width}>COD</TableCell>
                <TableCell width={itemsTable.cell2.width}>DESCRICAO</TableCell>
                <TableCell width={itemsTable.cell3.width}>QTD</TableCell>
                <TableCell width={itemsTable.cell4.width}>UNIT(R$)</TableCell>
                <TableCell width={itemsTable.cell5.width}>TOTAL(R$)</TableCell>
              </TableRow>
            </View>
            <View>
              {pdvSaleItems.map((item, index) => (
                <TableRow key={index}>
                  <TableCell width={itemsTable.cell1.width}>
                    {item.productId}
                  </TableCell>
                  <TableCell width={itemsTable.cell2.width}>
                    {item.product?.name ?? ''}
                  </TableCell>
                  <TableCell width={itemsTable.cell3.width}>
                    {item.quantity}
                  </TableCell>
                  <TableCell width={itemsTable.cell4.width}>
                    {formatIntegerToMoney(item.unitValue)}
                  </TableCell>
                  <TableCell width={itemsTable.cell5.width}>
                    {formatIntegerToMoney(item.unitValue)}
                  </TableCell>
                </TableRow>
              ))}
              <View style={styles.totals}>
                <TableRow>
                  <TableCell width={itemsTable.cell1.width}></TableCell>
                  <TableCell width={itemsTable.cell2.width}></TableCell>
                  <TableCell width={itemsTable.cell3.width}></TableCell>
                  <TableCell width={itemsTable.cell4.width}>DESCONTOS</TableCell>
                  <TableCell width={itemsTable.cell5.width}>
                    {formatIntegerToMoney(pdvSale.discount)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell width={itemsTable.cell1.width}></TableCell>
                  <TableCell width={itemsTable.cell2.width}></TableCell>
                  <TableCell width={itemsTable.cell3.width}></TableCell>
                  <TableCell width={itemsTable.cell4.width}>ACRESCIMOS</TableCell>
                  <TableCell width={itemsTable.cell5.width}>
                    {formatIntegerToMoney(pdvSale.addition)}
                  </TableCell>
                </TableRow>
              </View>
              <View style={styles.totals}>
                <TableRow>
                  <TableCell width={itemsTable.cell1.width}></TableCell>
                  <TableCell width={itemsTable.cell2.width}></TableCell>
                  <TableCell width={itemsTable.cell3.width}></TableCell>
                  <TableCell width={itemsTable.cell4.width}>TOTAL</TableCell>
                  <TableCell width={itemsTable.cell5.width}>
                    {formatIntegerToMoney(pdvSale.totalValue)}
                  </TableCell>
                </TableRow>
              </View>
              <View style={styles.totals}>
                <TableRow>
                  <TableCell width={itemsTable.cell1.width}></TableCell>
                  <TableCell width={itemsTable.cell2.width}></TableCell>
                  <TableCell width={itemsTable.cell3.width}></TableCell>
                  <TableCell width={itemsTable.cell4.width}>VALOR RECEBIDO</TableCell>
                  <TableCell width={itemsTable.cell5.width}>
                    {formatIntegerToMoney(pdvSale.paidValue)}
                  </TableCell>
                </TableRow>
              </View>
              <View style={styles.totals}>
                <TableRow>
                  <TableCell width={itemsTable.cell1.width}></TableCell>
                  <TableCell width={itemsTable.cell2.width}></TableCell>
                  <TableCell width={itemsTable.cell3.width}></TableCell>
                  <TableCell width={itemsTable.cell4.width}>TROCO</TableCell>
                  <TableCell width={itemsTable.cell5.width}>
                    {formatIntegerToMoney(pdvSale.changeValue)}
                  </TableCell>
                </TableRow>
              </View>
            </View>
          </Table>

          <Text style={styles.footer}>FORMAS DE PAGAMENTO</Text>
          <Table>
            <View>
              <TableRow>
                <TableCell width={paymentsTable.cell1.width}>DATA</TableCell>
                <TableCell width={paymentsTable.cell2.width}>FORMA DE PAGAMENTO</TableCell>
                <TableCell width={paymentsTable.cell3.width}>VALOR (R$)</TableCell>
              </TableRow>
            </View>
            <View>
              {pdvSalePayments.map((payment, index) => (
                <TableRow key={index}>
                  <TableCell width={paymentsTable.cell1.width}>
                    {payment.createdDate}
                  </TableCell>
                  <TableCell width={paymentsTable.cell2.width}>
                    {convertPaymentMethodKeyToName(payment.method)}
                  </TableCell>
                  <TableCell width={paymentsTable.cell3.width}>
                    {formatIntegerToMoney(payment.paidValue)}
                  </TableCell>
                </TableRow>
              ))}
            </View>
          </Table>
          {
            pdvSale.customerId ?
              <>
                <Text style={styles.footer}>CLIENTE</Text>
                <Table>
                  <View>
                    <TableRow>
                      <TableCell width={paymentsTable.cell1.width}>Nome</TableCell>
                      <TableCell width={paymentsTable.cell2.width}>CPF</TableCell>
                      <TableCell width={paymentsTable.cell3.width}>Telefone</TableCell>
                    </TableRow>
                  </View>
                  <View>
                    <TableRow>
                      <TableCell width={paymentsTable.cell1.width}>
                        {pdvSale.customerEntity.name}
                      </TableCell>
                      <TableCell width={paymentsTable.cell2.width}>
                        {pdvSale.customerEntity.cpf}
                      </TableCell>
                      <TableCell width={paymentsTable.cell3.width}>
                        { pdvSale.customerEntity.phone ? pdvSale.customerEntity.phone : pdvSale.customerEntity.cell}
                      </TableCell>
                    </TableRow>
                  </View>
                </Table>
              </>
              : <></>
          }

          <View style={styles.footer}>
            <Text>
              {getDate().fullDateStr}
            </Text>
            <Text>
              Emitido por Milliontech
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  );
}

