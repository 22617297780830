import React, { useCallback, useEffect, useState } from 'react';
import { Box, Grid, Paper, TextField } from '@material-ui/core';
import api from '../../../services/Api';
import { Badge, Button, Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const StartPage = () => {
    const [processList, setProcessList] = useState<any>([]);
    const imagesList = [
        `${window.location.origin}/media/bpmnProcessDefaultImages/01.jpg`,
        `${window.location.origin}/media/bpmnProcessDefaultImages/02.png`,
        `${window.location.origin}/media/bpmnProcessDefaultImages/03.jpg`,
        `${window.location.origin}/media/bpmnProcessDefaultImages/04.avif`,
        `${window.location.origin}/media/bpmnProcessDefaultImages/05.jpeg`,
        `${window.location.origin}/media/bpmnProcessDefaultImages/06.jpg`,
    ];

    useEffect(() => {
        const processFetch = async() => {
            let { data } = await api.get('/bpm/solicitacao-processo-formulario/defaultAll');
            if (data) {
                setProcessList(data);
            }
        }
        processFetch().then();
    }, []);

    return(
        <div>
            <h3>Processos</h3>
            <small>
                Escolha um processo pronto ou clique&nbsp;
                <a
                    href={"/bpm/solicitacao-processo-formulario/0"}
                    style={{color: '#3F51B5'}}
                >AQUI</a>
                &nbsp;para começar um do zero
            </small>
            <div>
                {
                    processList.map((p: any, i: number) =>
                        <Link
                            key={p.id}
                            style={{ color: 'inherit', textDecoration: 'inherit'}}
                            to={`/bpm/solicitacao-processo-formulario/${p.id}`}
                            title={p.name}
                        >
                            <div style={styles.processListItem}>
                                <div style={styles.imageDiv}>
                                    <img
                                        src={
                                            imagesList[i] ?
                                                imagesList[i]
                                                :
                                                imagesList[Math.floor(Math.random() * 6)]
                                        }
                                        alt="image"
                                        style={{height: 200,}}
                                    />
                                </div>
                                <div style={styles.processListItemContent}>
                                    <h6>{p.name.length > 29 ? `${p.name.substring(0, 26)}...` : p.name}</h6>
                                    <small>{p.description}</small>
                                    <small>Categoria: {p.category ? p.category.name : 'N/A'}</small>
                                </div>
                            </div>
                        </Link>
                    )
                }
            </div>
        </div>
    );
}

const styles = {
    processListItem: {
        border: '0px solid #FFF',
        borderRadius: 5,
        margin: '1%',
        width: '23%',
        float: 'left' as 'left',
        padding: 0,
        cursor: 'pointer' as 'pointer',
        boxShadow: '6px 7px 13px #BBB',
        height: 285,
        overflow: 'hidden',
    },
    processListItemContent: {
        padding: 15,
    },
    imageDiv: {
        borderTopLeftRadius: 5,
        borderTopRightRadius: 5,
        height: 200,
        width: '100%',
        overflow: 'hidden',
        textAlign: 'center' as 'center',
    }
}

export default StartPage;